@import "src/_mixin.scss";

.proCardMessage {
  $avatarSize: 50px;

  display: block;
  max-width: 320px;
  width: 80vw;
  color: $text-dark-grey;
  text-decoration: none;
  position: relative;

  &__inner {
    @include focus_state;
    @include border;

    position: relative;
    border-radius: 17.5px; //Same as .messages__item
    padding: 10px 15px 20px;
  }

  &__onBoardingHint {
    width: 320px;
    margin-bottom: 20px;
  }

  &__mainInfo {
    @include focus_state;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 auto;
    padding: 5px 10px 10px;
    border-radius: $global-border-radius;
    max-width: 80%;
    text-align: center;
  }

  &__nameContainer {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
  }

  &__avatarWrapper {
    position: relative;
    margin-bottom: 18px;
  }

  &__fullname {
    @include lato_font(16px, 20px, 700, $link-color);
    margin: 0 0 5px;
  }

  &__mainEdit {
    position: absolute;
    right: 15px;
    top: 15px;
    line-height: 100%;
  }

  $gusIconSize: 20px;
  &__profileLink {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-decoration: none !important;

    &_verified {
      &:before {
        content: '';
        display: inline-block;
        margin-right: 0.5ch;
        height: $gusIconSize;
        width: $gusIconSize;
        vertical-align: top;
        background-image: url('~common/ProviderBadges/img/gusIcon.svg');
        background-size: contain;
        background-position: center;
      }
    }
  }

  &__ratingSection {
    width: 100%;
    margin-bottom: 12px;
    text-align: center;
  }

  &__ratingWrapper {
    @include focus_state;
    width: 100%;
    border-radius: $global-border-radius;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__addRating {
    @include button_link(12px, 16px, 700);
    height: 20px;
    padding: 1px 5px;
    margin-left: -7px;
    vertical-align: top;
  }

  &__address {
    @include lato_font(12px, 16px, 400, $text-dark-grey);
    margin: 0;
    word-break: break-word;
  }

  &__stats {
    margin: 0 auto 15px;
    max-width: 200px;
    text-align: center;
  }

  &__badgesWrapper {
    padding: 15px;
    margin: 20px 0;
  }

  &__galleryWrapper {
    display: flex;
    margin-bottom: 20px;
  }

  &__gallery {
    width: 100%;
    max-width: 300px;
  }

  &__gallery &__galleryImage {
    $imageSize: 50px;
    width: $imageSize;
    height: $imageSize;
    flex-basis: $imageSize;
    margin-right: 5px;
  }

  &__createdAt {
    @include lato_font(12px, 15px, 400, $text-light-grey);
    margin: 0 0 15px;
  }

  &__link {
    @include button_link(14px, 18px, 700);
    margin: 0;
    text-decoration: none !important;
  }

  &__shortDescription {
    @include lato_font(12px, 16px, 400, $grey);
    word-wrap: break-word;
    white-space: initial;
    margin: 0;

    &_empty {
      color: $silver-chalice;
    }
  }

  &__descriptionWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin: 0 0 15px;
  }

  &__contactRow {
    display: flex;
    align-items: center;

    & + & {
      margin-top: 16px;
    }
  }

  &__contactIcon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 1ch;
    width: 18px;
    height: 18px;
    color: $silver-chalice;
  }

  &__phoneWrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__phone {
    display: inline-block;
    margin-right: 1rem;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    text-decoration: none !important;
  }

  &__profile {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    text-decoration: none !important;
  }

  &__btn {
    @include button_link(14px, 18px, 700);
    padding: 0;
  }

  &__settingsLink {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__settingsLinkIcon {
    display: block;

    &_floating {
      position: absolute;
      right: -35px;
      top: calc(50% - 15px);
      pointer-events: auto;
    }
  }

  &__providerCategoriesContainer {
    display: flex;
    margin-bottom: 8px;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__providerCategoriesLabel {
    @include lato_font(12px, 16px, 400, $text-dark-grey);
    margin-right: 5px;
    flex-grow: 0;
  }

  &__providerCategoriesActive {
    @include border;
    @include lato_font(12px, 16px, 700, $text-grey);
    border-radius: $global-border-radius;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    padding: 5px 7px;
  }

  &__providerCategoriesOther {
    @include button_secondary(12px, 16px, 400);
    flex: 0 0;
    padding: 6px 8px 4px 6px;
  }

  &__onboardingTooltip {
    margin-top: 20px;
    width: 100%;
  }

  &__verify {
    display: inline-flex;
    align-items: center;
    column-gap: 1ch;
    color: $text-error;
    font-size: 0.75rem;
    line-height: 1rem;

    &:hover {
      color: darken($text-error, 15%);
    }
  }
}
