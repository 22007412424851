@import "src/_mixin.scss";

.providerServices__title {
  margin-bottom: 30px;
}

.providerServices__body {
  position: relative;
  @include min_media('medium') {
    min-height: 300px;
  }
}

.providerServices__pill {
  display: flex;
  align-items: center;
  padding: 9px 15px;
  margin-top: 20px;
  border: 1px solid $border-light;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  color: $dark-grey;
  cursor: pointer;
  @include focus_state;

  @include min_media('medium') {
    max-width: calc(50% - 20px);
  }
}

.providerServices__pill_active {
  border-color: $cta;
  background-color: $cta;
  cursor: initial;
}

.providerServices__icon {
  margin-right: .5em;
}

.providerServices__nameGroup {
  flex-grow: 1;
}

.providerServices__chevron {
  transform: rotate(90deg);

  .providerServices__pill_active & {
    transform: rotate(-90deg);
  }

  @include min_media('medium') {
    transform: rotate(0); // always right

    .providerServices__pill_active & {
      transform: rotate(0);
    }
  }
}

.providerServices__list {
  padding-left: 0;
  margin: 0;
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: 0ms;

  @include min_media('medium') {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(50% - 20px);
  }
}

.providerServices__list_open {
  margin-top: 20px;
  max-height: 100%;
  overflow: auto;
  transition: 300ms;

  @include min_media('medium') {
    margin-top: 0;
    transition: 0ms;
    max-height: 999px;
  }
}

.providerServices__category {
  // margin between links is defined experimentally in Lighthouse
  // See https://web.dev/tap-targets/?utm_source=lighthouse&utm_medium=devtools
  margin-top: 4px;
  display: inline-block;

  &:not(:any-link) {
    color: inherit;
  }
}
