@import "src/_mixin.scss";

.providerContacts {
  margin: 20px 0;
  font-style: normal;

  .providerContacts {
    &__textLine {
      display: flex;
      align-items: center;
    }

    &__icon {
      flex-shrink: 0;
      color: $silver-chalice;
      margin-right: 10px;
    }

    &__shadowText {
      font-size: 13px;
      line-height: 17px;
      font-weight: bold;
      color: $dark-grey;
      overflow: hidden;
      text-overflow: ellipsis;

      // not BEM
      .shadowText__show,
      .shadowText__text {
        font-size: inherit;
      }

      .shadowText__text,
      .shadowText__link {
        color: $dark-grey;
      }
    }
  }
}

.providerContacts__textLine {
  & + & {
    margin-top: 15px;
  }
}

.providerContacts__badge {
  flex-shrink: 0;
  margin-left: 1rem;
}

.providerContacts__verify {
  display: inline-flex;
  align-items: center;
  column-gap: 1ch;
  margin-left: 1rem;
  color: $text-error;
  font-size: 0.75rem;
  line-height: 1rem;

  &:hover {
    color: darken($text-error, 15%);
  }
}

.providerContacts__inverse {
  color: $white;
}
