@import "src/_mixin.scss";

.providerPrices {
  color: $dark-grey;

  .collapsible + .collapsible {
    border-top: 1px solid $border-light;
  }
}

.providerPrices__subtitle {
  font-size: 13px;
  line-height: 17px;
  color: $dark-grey;
}

.providerPrices__heading {
  padding: 15px 0;
}

.providerPrices__toggle {
  padding: 2px 0 2px 25px;
  &:after {
    right: auto;
    left: 5px;
  }
}

.providerPrices__list {
  padding-left: 0;
  margin: 0;
}

.providerPrices__item {
  margin-bottom: 20px;
  break-inside: avoid-column;
}

// Table of content, solution with dots
// see: https://markentier.tech/posts/2021/03/responsive-toc-leader-lines-with-css/

.providerPrices__entry {
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-areas: "chapter page";
  align-items: end;
  grid-column-gap: 0.25ch;

  font-size: 13px;
  line-height: 17px;
}

.providerPrices__name {
  display: block;
  grid-area: chapter;
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    padding-left: .25ch;
    content: " . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
    text-align: right;
  }
}

.providerPrices__value {
  flex-shrink: 0;
  white-space: nowrap;
}

.providerPrices__empty {
  color: $text-placeholder;
}
