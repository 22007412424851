@import "src/_mixin.scss";

.providerFeedbacksEmpty {
  padding-top: 40px;
  text-align: center;
  > svg {
    margin-left: 30px;
  }
  .providerFeedbacksEmpty {
    &__title {
      margin-bottom: 5px;
    }
    &__rateButton {
      margin-top: 20px;
    }
  }
}
