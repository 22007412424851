@import "src/_mixin.scss";

.shadowText {
  display: inline-block;
  white-space: nowrap;

  &_inline {
    margin: 0 5px;
    height: 17px;
    &.shadowText_email {
      padding: 0 0 0 20px;
      background-size: 14px;
      background: url('../../common/Chat/img/email.svg') left bottom no-repeat;
    }
    &.shadowText_phone {
      padding: 0 0 0 20px;
      background-size: 14px;
      background: url('../../common/Chat/img/phone.svg') left center no-repeat;
    }
  }

  &_shown {
    padding: 0;
    background: none;
  }

  &__show {
    @include button_link();
    padding: 0;
    font-weight: 700;
  }

  &__text {
    color: $text-grey;
    font-size: 15px;
    font-weight: 700;
  }

  &_inline &__email {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 400;
  }

  &__link {
    @include focus_state;
    text-decoration: none !important;
    white-space: normal;
  }
}

// This edit was made to make element in a shadow state shorter
// and place with verification button on a single row
.shadowText__action {
  position: relative;
  padding-left: 0.25rem;
  background-color: $white;
  margin-left: -3ch;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 0.5rem;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba($white, 0%) 0%, rgba($white, 100%) 100%);
    background-repeat: no-repeat;
  }
}
