@import "src/_mixin.scss";

.avatar {
  position: relative;

  &__image {
    @include border(transparentize($border-light, 0.3));
    display: block;
    border-radius: 50%;

    &_muted {
      opacity: 0.6;
    }
  }

  &__badgeWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
