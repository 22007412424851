@import "src/_mixin.scss";

.providerBadges {
  display: flex;
  flex-wrap: wrap;

  &_smallMode {
    justify-content: space-around;
    flex-wrap: nowrap;
    column-gap: 5px;
  }

  @include media('mobile') {
    justify-content: space-around;
  }

  &__badge {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    align-items: center;
    position: relative;

    @include lato_font(12px, 17px, 400);
    text-align: center;

    @include min_media('medium') {
      font-size: 14px;
      flex-direction: row;
      flex-basis: 100%;
      text-align: left;

      & + & {
        margin-top: 15px;
      }
    }

    > a {
      display: block;
    }
  }

  &_smallMode &__badge {
    flex-direction: column;
    flex-basis: 33%;
    flex-grow: 1;
    max-width: 50%;
    margin: 0;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
  }

  &__badgeIcon {
    margin-right: 10px;
    height: 20px;

    @include media('mobile') {
      margin: 0 0 10px;
    }
  }

  &__badgeText {
    font-size: 10px;
    line-height: 12px;
    min-width: 60px;

    &_bold {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
    }
  }

  &_smallMode &__badgeIcon {
    margin: 0 0 2px;
  }

  &__tooltip {
    @include tooltip(250px, 13px);
    text-align: left;
    opacity: 0;
    user-select: none;

    transform: scale(0);
    transition: opacity 0.2s 0s, transform 0s 0.2s;

    &_rightCentered {
      left: initial;
      right: 0;

      &::after {
        right: 35px;
      }
    }
  }

  &__tooltipContentWrapper {
    @include tooltip_content_wrapper;
    @include lato_font(13px, 17px, 400, $text-dark-grey);
    position: relative;
  }

  &__badge:hover &__tooltip {
    transform: none;
    opacity: 1;
    transition: opacity 0.2s 0.2s, transform 0s 0.2s;
  }
}
