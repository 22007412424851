@import "src/_mixin.scss";

.providerFeedbacksSummary {
  &__headerSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include min_media('medium') {
      flex: 1 0;
    }
  }

  &__mainRating {
    @include lato_font(56px, 56px, 900);
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 15px;
      color: $cta;
    }
  }

  &__ratingDescription {
    margin-top: 20px;
  }

  &__ratingDescriptionExternal {
    display: block;
    margin-top: 10px;
  }

  &__center {
    align-items: center;

    .providerFeedbacksSummary__mainRating {
      margin-left: -40px;
    }

    .providerFeedbacksSummary__ratingDescription {
      text-align: center;
    }
  }

  &__link {
    margin-top: 0.5rem;
  }
}
