@import "src/_mixin.scss";

.checkboxIconList {
  font-size: 14px;
  line-height: 20px;
  color: $text-grey;
  padding-left: 0;
  margin-bottom: 35px;
  list-style-position: inside;

  &Item {
    @include check_mark;
    margin: 15px 0 15px 5px;
    position: relative;
    padding-left: 25px;
    &::before {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}
