@import "src/_mixin.scss";

.radio {
  position: fixed;
  z-index: -1;
  opacity: 0;

  &__label {
    @include lato_font(14px, 20px, 400, $text-dark-grey);

    display: inline-block;
    margin-bottom: 0;
    padding: 0 0 0 30px;
    border-radius: $global-border-radius;
    background: url('./img/radio.svg') left top no-repeat;
    white-space: initial;
    transition: background-image 0.1s;
    cursor: pointer;

    // Preload hover img to not blink
    &:after {
      content: url('./img/radio_hover.svg');
      position: absolute;
      z-index: -1;
      height: 0;
      width: 0;
      overflow: hidden;
    }

    &:hover {
      background-image: url('./img/radio_hover.svg');
    }

    &_error {
      background-image: url('./img/radio_error.svg');
    }

    &_disabled,
    &_disabled:hover {
      background-image: url('./img/radio_disabled.svg');
    }
  }

  &:focus + .radio__label {
    // TODO: discuss how to display focus state
    // background-image: url('./img/radio_hover.svg');
    outline: none;
  }

  &:checked + .radio__label,
  &:checked:hover + .radio__label {
    background-image: url('./img/radio_checked.svg');
  }

  &:checked:disabled + .radio__label {
    background-image: url('./img/radio_checked_disabled.svg');
  }
}
