@import "src/_mixin.scss";

$cardRadius: 5px;
$iconSize: 24px;

.providerInfo {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.01);

  @include min_media('medium') {
    border-radius: $cardRadius;
  }
}

.providerInfo__cover {
  position: relative;
  background-color: #ffd401;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 220px;

  @include min_media('medium') {
    border-top-left-radius: $cardRadius;
    border-top-right-radius: $cardRadius;
  }
}

.providerInfo__coverUpload {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: $geyser;
  color: $dark-grey;

  &:focus-within {
    outline: 2px solid $primary;
  }
}

.providerInfo__content {
  position: relative;
  margin-top: -13px;
  padding: 0 25px 30px 25px;
  background-color: $white;
  border-top-left-radius: $cardRadius;
  border-top-right-radius: $cardRadius;

  @include min_media('medium') {
    padding: 0 30px 30px;
    border-radius: $cardRadius;
  }
}

.providerInfo__label {
  padding: 0.4em 0.5em;
  border-radius: 0.3em;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.2;
  vertical-align: middle;
}

.providerInfo__label_cta {
  background-color: $cta;
}

$avatarSize: 140px;

.providerInfo__avatar {
  position: relative;
  display: inline-block;
  margin-top: (-$avatarSize/2);
  margin-bottom: 20px;
  vertical-align: top;
}

.providerInfo__avatar_interactive {
  cursor: pointer;

  &:focus-within .providerInfo__photo {
    outline: 2px solid $primary;
  }
}

.providerInfo__photo {
  display: block;
  width: $avatarSize;
  height: $avatarSize;
  border-radius: 50%;
  background-color: $border-dark;
}

.providerInfo__upload {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 6px;
  right: 6px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: $primary;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
}

.providerInfo__share {
  position: absolute;
  right: 25px;
  top: -24px;
}

.providerInfo__fab {
  position: relative;
}

$shareButtonSize: 2 * $iconSize;

.providerInfo__shareMenu {
  position: absolute;
  right: 0;
  bottom: $shareButtonSize/2;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  height: auto;
  max-height: 0;
  width: $shareButtonSize;
  border-top-left-radius: $shareButtonSize/2;
  border-top-right-radius: $shareButtonSize/2;
  transition: 200ms ease-out;

  @include min_media('medium') {
    top: 0;
    height: $shareButtonSize;
    width: auto;
    max-width: 0;
    right: $shareButtonSize/2;
    flex-direction: row;
    border-bottom-left-radius: $shareButtonSize/2;
    border-top-right-radius: 0;
  }
}

.providerInfo__shareMenu_open {
  max-height: 4 * $shareButtonSize;
  padding-bottom: $shareButtonSize/2;

  @include min_media('medium') {
    padding-right: $shareButtonSize/2;
    max-width: 4 * $shareButtonSize;
  }
}

.providerInfo__shareMethod {
  display: inline-block;
  padding: 0;
  border-width: 0;
  width: $shareButtonSize;
  height: $shareButtonSize;
  background-repeat: no-repeat;
  background-size: $iconSize $iconSize;
  background-position: 50% 50%;
  cursor: pointer;
}

.providerInfo__shareMethod_clipboard {
  background-image: url('~common/icons/copy-file.svg');
}

.providerInfo__title {
  display: flex;
  align-items: center;
  column-gap: 1ch;
  flex-wrap: wrap;
}

.providerInfo__row {
  margin-top: 10px;
  line-height: initial;
}

.providerInfo__status {
  font-size: 14px;
  line-height: 20px;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 4px;
    border-radius: 50%;
  }
}

.providerInfo__status_online {
  color: $lima;

  &::before {
    background-color: $lima;
  }
}

.providerInfo__status_offline {
  color: $silver-chalice;

  &::before {
    border: 2px solid $border-dark;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
  }
}

.providerInfo__address {
  margin-right: 0.5em;
  font-size: 14px;
  line-height: 20px;
  color: $grey;
}

.providerInfo__experience {
  font-size: 13px;
  line-height: 17px;
}
