@import "src/_mixin.scss";

.ratingView {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1ex;

  &__starRating {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__star {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 9px;
    vertical-align: text-bottom;
    background-image: url("~common/icons/new_gold_star.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__rating {
    @include lato_font(14px, 16px, 700, $text-dark-grey);
    margin-left: 7px;
    margin-right: 10px;
  }

  &__feedbackWrap {
    position: relative;
  }

  &__clientsTooltip {
    min-width: 100px;
    left: calc(50% - 50px);
    text-align: center;
    z-index: 10;
  }

  &__clientsTooltipContent {
    padding: 5px;
  }

  &__feedbackWrap:hover &__clientsTooltip {
    opacity: 1;
    transform: none;
  }

  &__clients {
    position: relative;
    //margin-left: 4px;
  }

  &__countWrap {
    display: flex;
    column-gap: 4px;
  }
}
