@import "src/_mixin.scss";

.providerAbout__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.providerAbout__expandable {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;

  // should look like <TextP3>
  font-size: 14px;
  line-height: 20px;
  color: $text-grey;

  @include min_media('medium') {
    -webkit-line-clamp: 3;
  }
}

.providerAbout__expandable_expanded {
  max-height: unset;
  -webkit-line-clamp: unset;
}

.providerAbout__toggle {
  display: block;
  margin: 20px auto auto;
}

.providerAbout__photos {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  margin-top: 30px;

  @include min_media('medium') {
    grid-template-columns: repeat(6, minmax(80px, 1fr));
  }
}

.providerAbout__thumb {
  padding: 100% 0 0;

  @supports (aspect-ratio: 1/1) {
    aspect-ratio: 1/1;
    padding: 0;
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &[data-rest] {
    position: relative;

    &:after {
      content: '+' attr(data-rest);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
      color: $white;
    }
  }
}
