@import "src/_mixin.scss";

.selectServicesModal {
  &__options{
    margin-top: 35px;
  }

  &__l2Item {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    background-position-y: center;
  }

  &__l2Icon {
    display: block;
    margin-right: 15px;

    svg {
      display: block;
      width: 32px;
      height: 32px;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 25px;
    justify-content: flex-end;
  }

  &__button {
    width: 175px;
    margin-left: 15px;
  }
}
