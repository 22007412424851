@import "src/_mixin.scss";

.providerServiceArea {
  margin: 15px -15px 0;

  .providerServiceArea {
    &__map {
      height: 160px;
      filter: saturate(0.5);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @supports (aspect-ratio: 300/160) {
        aspect-ratio: 300/160;
        width: 100%;
        height: auto;
      }
    }

    &__mapContainer {
      position: relative;
    }

    &__circle {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 120px;
      height: 120px;
      background-image: url(./map-pin.svg);
      background-size: 34px 51px;
      background-position: 50% 12px;
      background-repeat: no-repeat;
      background-color: rgba($primary, 0.2);
      border-radius: 50%;
    }

    &__cities {
      padding: 15px 15px 0;
    }

    &__cityLink {
      color: $dark-grey;
    }
  }
}
