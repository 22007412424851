@import "src/_mixin.scss";

.pwfAdvantagesModal {
  background: $white url('~common/icons/pwf_modal_bg.svg') no-repeat top right;
  &__content {
    max-width: 500px;
    padding: 45px 35px 0 35px;
  }
  .payWithFixlyFeature__titleIcon {
    background: none;
    padding-left: 0;
    max-width: 210px;
  }
}
