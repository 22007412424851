@import "src/_mixin.scss";

.imageSlider {
  display: flex;
  align-items: center;
  position: relative;
  width: 80vw;
  max-width: 100%;
  margin: auto;

  &__imageWrapper {
    width: 100%;
    overflow-x: hidden;

    @include media("mobile") {
      overflow-x: auto;
    }
  }

  &__imageWrapperTrunk {
    display: flex;
    width: 100%;
  }

  &__clickableWrapper {
    @include focus_state;
    background: $mercury;
    overflow: hidden;
    border: none;
    border-radius: $global-border-radius;
    padding: 0;
    margin-right: 20px;
    cursor: pointer;
  }

  &__image {
    margin-right: 20px;
    border-radius: $global-border-radius;
    overflow: hidden;
    background: $mercury no-repeat center;
    background-size: cover;

    @include media('mobile') {
      margin-right: 20px;
    }

    &_withWrapper {
      flex: none;
      margin: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__lastImagePreview {
    @include lato_font(12px, 16px, 400, $white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: -100%;
    background: rgba(0, 0, 0, .6);
  }

  &__button {
    @include button_link;

    position: absolute;
    top: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background: no-repeat center;
    background-size: 10px 16px;

    @include media('mobile') {
      display: none;
    }

    &_left {
      left: -60px;
      background-image: url('../icons/arrow_left_black.svg');
    }

    &_right {
      right: -60px;
      background-image: url('../icons/arrow_right_black.svg');
    }

    &_hidden {
      display: none;
    }
  }
}
