@import "src/_mixin.scss";

$layout-gap: 20px;

.ProviderProfileView {
  background-color: #f6f6f6;
  width: 100%;

  @include min_media('medium') {
    padding-bottom: 50px;
  }
}

.ProviderProfileView__layout {
  display: grid;
  grid-gap: $layout-gap;
  grid-template-columns: minmax(0, 1fr);
  max-width: $container-width;

  @include min_media('medium') {
    grid-template-columns: minmax(0, 1fr) 300px;
    margin: 0 auto;
  }
}

.ProviderProfileView__cell {
  @include min_media('medium') {
    grid-column: 1/2;
  }
}

.ProviderProfileView__sidebar {
  padding: 0 15px;
  max-width: 100vw;
  @include min_media('medium') {
    grid-column: 2/3;
    // grid-row: 1/-1 cannot be applied in explicit grid
    // so span N is used to group enough space for sidebar content
    // see https://stackoverflow.com/questions/63775143/how-does-grid-row-1-1-actually-work-references-needed
    grid-row: span 10;
    padding: 0;
  }

  .ProviderProfileView__card {
    & + .ProviderProfileView__card {
      margin-top: 10px;
    }
  }
}

.ProviderProfileView__stickyInner {
  @include min_media('medium') {
    position: sticky;
    top: 0;
  }
}

.ProviderProfileView__card {
  background-color: #ffffff;
  padding: 30px 25px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.01);

  @include min_media('medium') {
    padding: 30px;
    border-radius: 5px;
  }

  &_dense {
    border-radius: 5px;
    padding: 15px;
  }

}

.ProviderProfileView__slugText {
  font-size: 13px;
  margin-top: 15px;
}

.ProviderProfileView__slugLink {
  color: $black;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  padding-left: 35px;
  margin-top: 15px;
  background: url('~common/icons/link_grey.svg') no-repeat 0 -2px;
}

.ProviderProfileView__tabs {
  padding: 0;
  z-index: 1;
  position: sticky;
  top: 50px;

  @include min_media('medium') {
    padding: 0 15px;
    top: 0;
  }
}

.ProviderProfileView__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &-gray {
    color: $silver-chalice;
  }
}
