@import "src/_mixin.scss";

.providerFeedbacksItem {
  width: 100%;
  padding-top: 30px;

  @include min_media('medium') {
    border-bottom: 1px solid $geyser;
    display: flex;
    padding-bottom: 30px;
  }
  &:last-child {
    border-bottom: none;
  }
  &__side {
    display: flex;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid $geyser;
    padding-bottom: 15px;
    @include min_media('medium') {
      width: 160px;
      flex-shrink: 0;
      display: block;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  &__info {
    order: 2;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include min_media('medium') {
      margin: 0;
      order: 0;
      align-items: flex-start;
    }
  }
  &__date {
    color: $silver-chalice;
    margin-top: 5px;
    @include min_media('medium') {
      margin-top: 15px;
    }
  }
  &__internalLabel {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    @include min_media('medium') {
      margin-top: 15px;
    }
    > svg {
      margin-right: 10px;
    }
  }
  &__author {
    display: flex;
    align-items: center;
    @include min_media('medium') {
      margin-top: 15px;
    }
  }
  &__authorDetails {
    margin-left: 10px;
  }
  &__authorName {
    font-size: 12px;
    color: $dark-grey;
  }
  &__authorCity {
    font-size: 13px;
    color: $silver-chalice;
  }
  &__main {
    flex-grow: 1;
  }
  &__categoryName {
    margin-top: 15px;
    @include min_media('medium') {
      margin-top: 0;
    }
  }
  &__comment {
    overflow: hidden;
    margin-top: 15px;
  }
  &__reply {
    margin-top: 20px;
  }
  &__replyToggle {
    display: flex;
    align-items: center;
    & > svg {
      margin-left: 5px;
      transition: transform 0.3s;
    }
    &_open {
      & > svg {
        transform: rotate(180deg);
      }
    }
  }
  &__replyContainer {
    margin-top: 20px;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s;

    &_open {
      opacity: 1;
      max-height: 900px;
      overflow: visible;
    }
  }
  &__replyAuthor {
    display: flex;
    align-items: center;
  }
  &__replyDetails {
    margin-left: 10px;
  }
  &__replyName {
    font-size: 12px;
    color: $dark-grey;
    & > span {
      color: $silver-chalice;
    }
  }
  &__replyDate {
    font-size: 13px;
    color: $silver-chalice;
  }
  &__replyStateIcon {
    margin-left: auto;
    position: relative;
    &:hover {
      .providerFeedbacksItem__replyStateTooltip {
        opacity: 1;
        z-index: 100;
      }
    }
  }
  &__replyComment {
    margin-top: 10px;
  }

  &__imageSlider {
    margin: 15px 0;
    width: 100%;
  }

  &__thumb {
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }

  &__restCategories {
    position: relative;
    margin-left: 15px;
  }

  &__tooltip {
    top: 20px;
    bottom: auto;
    display: none;

    @include min_media('medium') {
      display: block;
    }

    &::before {
      z-index: 10;
      top: -5px;
    }
    &::after {
      top: -5px;
      z-index: 12;
    }
  }
  &__tooltipContent {
    z-index: 11;
    & p + p {
      margin-top: 10px;
    }
  }

  &__categoryCount {
    opacity: 1;
    font-weight: 400;
    color: $silver-chalice;
    cursor: default;
    &:hover + .fixlyTooltip {
      opacity: 1;
    }
  }
}

.feedbackItemReplyForm {
  margin-top: 30px;
  width: 100%;

  &__input {
    margin-top: 10px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    & > button {
      margin-left: 20px;
      min-width: 125px;
    }
  }
}
