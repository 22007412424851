@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "src/_mixin.scss";

.imageGallery {
  background: transparent;

  &__slide {
    $modalOverlayPadding: 50px;
    $modalContentPadding: 50px;
    $totalModalPaddingHeight: ($modalOverlayPadding + $modalContentPadding) * 2;
    $thumbnailBarHeight: 70px;
    width: calc(100vw - #{$modalContentPadding} * 2);
    height: calc(100vh - #{$thumbnailBarHeight} - #{$totalModalPaddingHeight});
    display: flex;
    align-items: center;
    justify-content: center;

    &.center {
      position: relative;
    }

    @include media('mobile') {
      $modalOverlayPadding: 0;
      $modalContentPadding: 15px;
      $totalModalPaddingHeight: 15px + 65px;
      $thumbnailBarHeight: 56px;
      width: calc(100vw - #{$modalContentPadding} * 2);
      height: calc(100vh - #{$thumbnailBarHeight} - #{$totalModalPaddingHeight});
    }
  }

  &__slideInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
  }

  &__item {
    max-width: 100%;
    min-height: 0;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__description {
    color: $white;
    line-height: 1;
    padding: 10px;

    @include media('mobile') {
      font-size: .8em;
      padding: 8px;
    }

  }

  &__thumbnail {
    $sideSize: 60px;
    position: relative;
    width: $sideSize;
    height: $sideSize;
    border-radius: $global-border-radius;
    border-width: 0;
    filter: brightness(.6);
    transition: filter 500ms;
    overflow: hidden;
    cursor: pointer;
    @include focus_state();

    &.active {
      filter: brightness(1);
      border-width: 0;
    }

    &:hover {
      border-width: 0;
      filter: brightness(1);
    }

    &:focus {
      border-width: 0;
    }

    & + & {
      margin-left: 10px; // horizontal gap between thumbnails
    }

    @include media('mobile') {
      $mobileSideSize: 50px;
      width: $mobileSideSize;
      height: $mobileSideSize;
    }

  }

  &__thumbImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, .75);
  }

  &__nav {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    cursor: pointer;
    opacity: .75;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
      @include focus_state()
    }

    @include media('mobile') {
      display: none;
    }
  }

  &__nav_left {
    left: 0;
  }

  &__nav_right {
    right: 0;
  }

  &__nav_left &__arrow {
    background-image: url("~common/icons/arrow_left_black.svg");
    background-position-x: 47%;
  }

  &__nav_right &__arrow {
    background-image: url("~common/icons/arrow_right_black.svg");
    background-position-x: 53%;
  }

  &__arrow {
    $circleSize: 45px;
    display: block;
    width: $circleSize;
    height: $circleSize;
    background-color: $cta;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 50%;
  }

  &__modalContent {
    @include media('mobile') {
      min-width: 100vw;
    }
  }
}
