@import "src/_mixin.scss";

$notificationSize: 18px;
.notificationClient {
  @include lato_font(10px, 14px, 700, #fff);

  display: inline-block;
  height: $notificationSize;
  min-width: $notificationSize;
  border-radius: $notificationSize / 2;
  background: $primary;
  border: 1px solid transparent;
  text-align: center;

  &_urgent {
    background: $badge-color;
    border-color: $dark-grey;
  }
}
