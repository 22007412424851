@import "src/_mixin.scss";

.providerBadge {
  @include lato_font(10px, 12px, 700);
  background: $cta;
  padding: 4px 5px;
  border-radius: $global-border-radius;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  &_featured {
    background: $cta;
    color: $text-dark-grey;
  }
  &_newcomer {
    background: $primary;
    color: $white;
  }
}
