@import "src/_mixin.scss";

.payWithFixly {
  max-width: 426px;
  .payWithFixly__title {
    margin-bottom: 10px;
  }
  &__titleWithIcon {
    display: flex;
    margin-bottom: 10px;
  }
  &__titleIcon {
    width: 51px;
    height: 46px;
    margin-left: 80px;
  }
  &__bottomSpace {
    margin-bottom: 30px !important;
    &_large {
      margin-bottom: 50px;
    }
    &_small {
      margin-bottom: 20px;
    }
    &_xsmall {
      margin-bottom: 10px !important;
    }
  }
  &__textCentered {
    text-align: center;
  }
  &__submitButtonFixed {
    text-align: center;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px 200px 25px 0;
    background-color: $white;
    border-top: 1px solid $alto;
  }
  #payuRegulationsAcceptance iframe {
    min-height: 20px !important;
  }
  &__termsText {
    margin: 10px 0 0 19px;
  }
  &__discList {
    list-style-type: disc;
    font-size: 13px;
    margin: 20px 0;
    padding-left: 24px;
    > li {
      line-height: 17px;
      margin-bottom: 18px;
    }
  }
  &__ibanNotProvided {
    color: $error;
  }
  &__specialBadge {
    border-color: #f6f6f6;
    padding: 38px 19px 38px 106px;
    background-size: 71px;
    background-position-y: 15px;
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.05);
  }
  &__termsCheckbox {
    margin: 30px 0;
  }

  &__explanationList {
    font-size: 13px;
  }
  &__radioText {
    margin-top: 10px;
  }
  &__radioButton {
    .radio__wrap {
      position: relative;
    }
    .radio__label {
      border-width: 1px;
    }
  }
  &__radioButtonIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
  }
  &__radioButton_selected {
    .payWithFixly__radioButtonIcon {
      color: $primary;
      top: 14px;
      right: 14px;
    }
    .radio__label {
      position: relative;
      border-color: $primary;
      > p {
        color: $primary;
      }
    }
  }
  &__errorMessage {
    margin-top: 1rem;
  }
}
