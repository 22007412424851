@import "src/_mixin.scss";

.verificationStatusPanel {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  padding: 15px;
  background-color: $white;

  @include min_media('medium') {
    padding: 15px 30px;
    align-items: center;
    border-radius: 5px;
  }

  &__body {
    @include min_media('medium') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
      flex-grow: 1;
    }
  }

  &__children {
    margin-top: 5px;
  }

  &__warning {
    color: $error;
    text-transform: lowercase;
  }

  &__badge {
    opacity: 0.4;

    & svg {
      vertical-align: top;
    }
  }

  &__heading {
    color: $silver-chalice;
  }

  &_verified {
    .verificationStatusPanel__badge {
      opacity: 1;
    }

    .verificationStatusPanel__heading {
      color: inherit;
    }
  }
}
