@import "src/_mixin.scss";

.providerAvatarWithBadge {
  position: relative;
  padding-bottom: 10px;

  &__badgeWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__badge {
    white-space: nowrap;
  }

  &__ratedStar {
    width: 14px;
    height: 13px;
    position: absolute;
    top: -2px;
    right: 3px;
  }
}
