@import "src/_mixin.scss";

.providerFeedbacks {
  .providerFeedbacks {
    &__headerSummary {
      @include min_media('medium') {
        align-items: flex-start;
      }
    }

    &__header {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include min_media('medium') {
        flex-direction: row;
        align-items: stretch;
      }
    }

    &__headerSummaryBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include min_media('medium') {
        flex: 1 0;
        align-items: flex-start;
        border-right: solid 1px $alto;
      }
    }

    &__headerStars {
      flex: 1 0;
      min-width: 250px;
      margin-top: 30px;

      @include min_media('medium') {
        padding-left: 35px;
        margin-top: 0;
      }
    }

    &__categoryFilter {
      margin-top: 30px;

      &>button {
        margin-right: 10px;
        margin-bottom: 10px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        padding: 0 15px;
        text-transform: none;
        font-size: 13px;
        font-weight: 400;
      }
    }

    &__textSearch {
      margin-top: 30px;

      .input {
        padding-left: 50px;
        background: url('./assets/search.svg') no-repeat 15px center;
      }
    }

    &__results {
      margin-top: 30px;
    }

    &__pagination {
      text-align: center;
    }

    &__disclaimer {
      margin-top: 8px;
      font-size: 14px;
      color: #999;
      line-height: 1.2;
      background: #fafafa;
      padding: 10px;
      border-radius: 10px;
    }
  }
}

.starFilterItem {
  width: 100%;
  margin-bottom: 8px;

  .starFilterItem {
    &__label {
      display: flex;
      align-items: center;
    }

    &__svg {
      width: 12px;
      margin-right: 6px;
      color: $alto;
    }

    &__rate {
      margin-right: 15px;
    }

    &__progressBar {
      flex-grow: 1;
      max-width: 130px;
      margin-right: 10px;
      height: 8px;
      background-color: $geyser;
      border-radius: 2px;
    }

    &__progressBarProgress {
      border-radius: 2px;
      height: 100%;
      background-color: $primary;
    }

    &__percentage {
      min-width: 5ch;
      white-space: nowrap;
      text-align: right;
    }
  }
}