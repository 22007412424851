@import "src/_mixin.scss";

.textarea {
  @include lato_font(15px, 22px, 400, $text-dark-grey);
  @include border;

  box-sizing: border-box;
  display: block;
  width: 100%;
  min-height: 125px;
  padding: 10px;
  border-radius: 4px;
  background: $white;
  resize: vertical;
  transition: border-color 0.2s;

  &::placeholder {
    color: $text-light-grey;
  }

  &:focus {
    border-color: $primary;
  }

  &_error {
    border-color: $error-color;
  }

  &_withFileUpload {
    border: none !important;
    box-shadow: none !important;
    outline: none;
  }

  &__wrap {
    width: 100%;
  }

  &__error {
    @include error_text;
  }
}
