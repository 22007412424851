@import "src/_mixin.scss";

.providerCompanyModal__body {
  color: $dark-grey;
  max-width: 500px;
}

.providerCompanyModal__row {
  & + & {
    margin-top: 25px;
  }

  &_grouped {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    @include min_media('medium') {
      flex-direction: row;
      column-gap: 50px;
    }
  }
}

.providerCompanyModal__label {
  color: $grey;
}
