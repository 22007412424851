@import "src/_mixin.scss";

.checkbox {
  position: fixed;
  z-index: -1;
  opacity: 0;

  &__wrap {
    line-height: 20px;
  }

  &__label {
    @include lato_font(14px, 20px, 400, $text-dark-grey);

    display: inline-block;
    margin-bottom: 0;
    padding: 0 0 0 30px;
    border-radius: $global-border-radius;
    background: url('./img/checkbox.svg') left top no-repeat;
    white-space: initial;
    transition: background-image 0.1s, color 0.1s;
    cursor: pointer;

    // Preload hover img to not blink
    &:after {
      content: url('./img/checkbox_hover.svg');
      position: absolute;
      z-index: -1;
      height: 0;
      width: 0;
      overflow: hidden;
    }

    &:hover {
      background-image: url('./img/checkbox_hover.svg');
    }

    &_error {
      background-image: url('./img/checkbox_error.svg');
    }

    &_disabled,
    &_disabled:hover {
      background-image: url('./img/checkbox_disabled.svg');
      color: $text-disabled;
    }
  }

  &:checked + .checkbox__label {
    background-image: url('./img/checkbox_checked.svg');
  }

  &:checked:disabled + .checkbox__label {
    background-image: url('./img/checkbox_checked_disabled.svg');
  }

  &:focus + .checkbox__label {
    box-shadow: 0 0 0 3px $link-focus-ring-color;
  }

  .focus_ring_hidden &:focus + .checkbox__label {
    outline: none;
    box-shadow: none;
  }
}
