@import "src/_mixin.scss";

.profileActivationCard__heading {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 15px;
}

.profileActivationCard__lock {
  width: 19px;
  height: 19px;
  color: $silver-chalice;
}

.profileActivationCard__contacts {
  padding-left: 0;
  margin: 0;
}

.profileActivationCard__pseudoContact {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: $text-disabled;
  user-select: none;

  & + & {
    margin-top: 15px;
  }
}

.profileActivationCard__map {
  position: relative;
  margin-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    user-select: none;
  }
}

.profileActivationCard__footer {
  margin: 20px -15px -15px;
  padding: 15px;
  overflow: hidden;
  background-color: $early-dawn;
  text-align: center;
}

.profileActivationCard__icon {
  vertical-align: middle;
}

.profileActivationCard__action {
  margin-top: 20px;
}
