@import "src/_mixin.scss";

.collapsible__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @include focus_state;
}

.collapsible__content {
  will-change: height, opacity;
  transition-duration: 225ms;
  transition-property: height, opacity;
  // Standard curve
  // Elements quickly accelerate and slowly decelerate between on-screen locations.
  // see https://material.io/archive/guidelines/motion/duration-easing.html#duration-easing-natural-easing-curves
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);

  @include min_media('medium') {
    transition-duration: 175ms;
  }
}

.collapsible__body {
  padding: 1rem 0;
}

.collapsible__indicator {
  flex-shrink: 0;
  margin-left: 1rem;
  transition: 225ms;
  @include min_media('medium') {
    transition-duration: 175ms;
  }
}

.collapsible__indicator_expanded {
  transform: rotate(-180deg);
}
