@import "src/_mixin.scss";

.pwfFeatures {
  &__title {
    margin-bottom: 20px;
    &_icon {
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 0 60px 0 65px;
      margin-bottom: 20px;
      background: url('~common/badges/pwf.svg') left center no-repeat;
      background-size: 48px;
    }
  }
  &__listTitle,
  &__description {
    margin-bottom: 20px;
  }
  &__faqLink {
    font-size: 15px;
    display: inline-block;
    margin-bottom: 30px;
  }
  &__list {
    color: $dark-grey;
    > li {
      @include check_mark;
      &::before {
        left: 8px;
        border-color: $lima;
      }
    }
  }
}
