@import "src/_mixin.scss";

.providerExternalLinks {
  margin: 15px 0;
}

$iconSize: 24px;

.providerExternalLinks__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: $iconSize;
  padding: 12px 8px 12px 16px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  color: currentColor;
  border: 1px solid $alto;
  border-radius: 6px;

  &:after {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: $iconSize;
    height: $iconSize;
    background-image: url('~common/icons/arrow_grey.svg');
    background-position: center;
    background-repeat: no-repeat;
    transform-origin: 50% 50%;
    transform: rotate(90deg);
  }

  &:not(:link,:visited) {
    color: $text-disabled;
  }
}

.providerExternalLinks__item + .providerExternalLinks__item {
  margin-top: 10px;
}

.providerExternalLinks__truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
