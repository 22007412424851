@import "src/_mixin.scss";

.onboardingTooltip {
  @include tooltip(300px);
  width: 280px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  opacity: 1;

  &::before,
  &::after {
    box-shadow: none;
    border: none;
    border-radius: 3px;
  }
  &::before {
    background-color: #ffd700;
    z-index: 10;
    bottom: -5px;
  }
  &::after {
    bottom: -1px;
    z-index: 11;
  }

  &__bottom {
    &::before {
      background-color: #ffd700;
      z-index: 10;
      top: -7px;
    }
    &::after {
      top: -3px;
      z-index: 11;
    }
  }

  &__content {
    @include tooltip_content_wrapper;
    border: 3px solid #ffd700;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    text-align: center;
  }
}
