@import "src/_mixin.scss";

.profileEditButton {
  color: $silver-chalice;

  &:hover {
    color: inherit;
  }
}

.profileEditButton__icon {
  vertical-align: top;
  pointer-events: none;
}
