@import "src/_mixin.scss";

.ProviderSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__count {
    color: $silver-chalice;
  }
}
