@import "src/_mixin.scss";

.preventCheckbox {
  &__modalTitle {
    margin-bottom: 15px;
    text-align: center;
  }

  &__modalDescription {
    margin-bottom: 35px;
    text-align: center;
  }

  &__modalButtons {
    width: 260px;
    margin: 0 auto;

    &_email {
      width: 100%;
    }
  }

  &__modalButton {
    &_unsubscribe {
      @include button_cta;
      display: block;
      width: 100%;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    &_close {
      @include button_secondary;
      display: block;
      width: 100%;
      text-transform: uppercase;
    }
  }
  &__content {
    margin: 0 auto;
    max-width: 450px;
  }
}

