@import "src/_mixin.scss";

.profileNavigation {
  margin: 0;
  padding: 0;
  display: flex;
  .profileNavigation {
    &__link {
      padding: 0 15px;
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: solid 4px transparent;
      border-top: solid 4px transparent;
      cursor: pointer;
      color: $dark-grey;
      transition: border-bottom-color 0.3s;
      &:hover,
      &_active {
        border-bottom-color: $cta;
      }
    }
  }
}
