@import "src/_mixin.scss";

.textareaWithCounter {
  position: relative;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  &__inputCounterWrapper {
    display: flex;
    flex-flow: row nowrap;
  }

  &__input {
    @include focus_state;

    display: block;
    height: 75px;
    resize: none;
    flex: 1;

    &_error {
      border-color: $error !important;
    }
  }

  &__counterWrapper {
    position: relative;
    flex: 0 1 0px;
    z-index: 1;
  }

  &__counter {
    @include lato_font(13px, 17px, 400, $silver-chalice);
    position: absolute;
    bottom: 10px;
    right: 10px;
    white-space: nowrap;

    &_withFileUpload {
      top: calc(100% + 5px);
    }
  }

  &__errorLabel {
    @include font_params(13px, 16px, 400, $error);
    display: block;
    margin: 5px 0;
    text-align: left;
    border: none;
    padding: 0;
  }
}
