@import "src/_mixin.scss";

.selectableItem {
  margin-bottom: 10px;

  &__label {
    display: block;
    font-size: 16px;
    padding: 11px 16px 13px 50px;
    border: 1px solid $border-light;
    background-position: 15px 12px;
    transition: background-color .2s ease;

    &:hover {
      background-color: $grey-settings;
    }
  }
}
